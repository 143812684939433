export const seo = {
  url: 'company/about-us',
  title: {
    pl: 'Poznaj Sea & Air | O nas',
    en: 'Discover Sea & Air | About us',
  },
  desc: {
    pl: 'Odkryj Sea & Air. Polską firmę spedycyjną z międzynarodowym zasięgiem. Poznaj naszą historię, osiągnięcia, aktualności i usługi transportowe.',
    en: 'Welcome to Sea & Air. Polish forwarding company with an international reach. Discover our history, achievements, news and transport services.',
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'firma spedycyjna',
    'firma logistyczna',
    'firma transportowa',
    'o-nas',
    'tsl',
  ],
}

export const intro = {
  title: {
    pl: 'Poznaj Sea & Air',
    en: 'Discover Sea & Air',
  },
  desc: {
    pl: 'Odkryj Sea & Air. Polską firmę spedycyjną z międzynarodowym zasięgiem. Poznaj naszą historię, osiągnięcia, aktualności i usługi transportowe.',
    en: 'Welcome to Sea & Air. Polish forwarding company with an international reach. Discover our history, achievements, news and transport services.',
  },
}

export const main = {
  title: {
    pl: 'Dlaczego warto wybrać firmę<br/>Omida Sea And Air S.A.?',
    en: 'Why is it worth choosing<br/>Omida Sea And Air S.A.?',
  },
  subtitle: {
    pl: 'O Firmie',
    en: 'About Company',
  },
  body: {
    pl: `
    <p>
    Jesteśmy firmą z <strong>polskim kapitałem</strong>, europejskim duchem i globalnym zasięgiem. Od 2012 roku rośniemy i umacniamy swoją wiodącą pozycję na rynku usług TSL.
    </p>
    <p>
    Dbając o podnoszenie jakości oferowanych usług, wsłuchujemy się w potrzeby naszych Klientów. Obserwujemy światowe trendy w logistyce i pogłębiamy nieustannie naszą wiedzę w poszukiwaniu optymalnych rozwiązań dla obsługi powierzonych nam ładunków.
    </p>
    <p>
    Naszym Klientom oferujemy kompleksowe rozwiązania logistyczne w <a href="/transport/sea/">transporcie morskim</a>, <a href="/transport/air/">lotniczym</a>, <a href="/transport/land/kolejowy/">kolejowym</a> i <a href="/transport/land/drogowy/">drogowym</a>. Doradzamy im w sprawach <a href="/services/custom-agency/">celnych</a> i <a href="/services/insurance/">ubezpieczeniowych</a>.
    </p>
    <p>
    Nasz sukces tworzą ludzie oddani swojej pracy – kreatywni, zaangażowani i pozytywnie nastawieni – wspierający Klientów fachową wiedzą i doświadczeniem.
    </p>
    <p>
    Jesteśmy postrzegani i doceniani przez naszych Klientów jako solidny partner biznesowy.
    </p>
    `,
    en: `
    <p>
    We are a company with <strong>Polish capital</strong>, European spirit and global reach.
    Since 2012 we have been growing and strengthening our leading position on the TSL services market.
    </p>
    <p>
    Taking care to improve the quality of our services, we listen to the needs of our Customers. We observe global trends in logistics and continuously broaden our knowledge in search of optimal solutions for handling the cargo entrusted to us.
    </p>
    <p>
    We offer our clients comprehensive logistics solutions in sea, air, rail and road transport. We advise them on customs and insurance matters.
    </p>
    <p>
    Our success is created by people dedicated to their work - creative, committed and positive - supporting our Clients with their expertise and experience.
    </p>
    <p>
    We are perceived and appreciated by our Clients as a reliable business partner.
    </p>
    `,
  },
  sections: [
    {
      title: {
        pl: 'Współpraca',
        en: 'Cooperation',
      },
      desc: {
        pl: 'Współpracując z siecią zaufanych i sprawdzonych partnerów świadczymy profesjonalne usługi o zasięgu globalnym.',
        en: 'Working with a network of trusted and proven partners, we provide professional services with a global reach.',
      },
    },
    {
      title: {
        pl: 'Specjalizacja',
        en: 'Specialization',
      },
      desc: {
        pl: 'Specjalizujemy się w transporcie towarów drogą morską. Dostarczamy ładunki pełnokontenerowe (FCL), drobnicowe (LCL), jak również ponadgabarytowe, konwencjonalne i masowe.',
        en: 'We specialize in transporting goods by sea. We deliver full container loads (FCL), less-than-container loads (LCL) as well as oversized, conventional and bulk cargo.',
      },
    },
  ],
}

export const vision = {
  title: {
    pl: 'Wartości, jakimi się kierujemy',
    en: 'The values ​​we follow',
  },
  subtitle: {
    pl: 'Misja & Wizja',
    en: 'Mission & Vision',
  },
  body: {
    pl: `
    <p>
    Wartości, którymi kierujemy się w codziennej pracy, to przede wszystkim <strong>profesjonalizm</strong>, <strong>zaangażowanie</strong>, <strong>odpowiedzialność</strong> i <strong>wzajemny szacunek</strong>.
    </p>
    <p>
    Doskonalimy nasze umiejętności, pogłębiamy wiedzę, zdobywamy nowe doświadczenia,
    troszcząc się o rozwój osobisty i sukces naszych Klientów.
    </p>
    <p>
    Otwarci na świat i ludzi wspieramy nowe pomysły oraz umacniamy ducha pracy zespołowej!
    </p>
    `,
    en: `
    <p>
    The values that guide us in our daily work are above all <strong>professionalism</strong>, <strong>commitment</strong>, <strong>responsibility</strong> and <strong>mutual respect</strong>.
    </p>
    <p>
    We improve our skills, broaden our knowledge and gain new experiences, taking care of our personal development and our clients' success.
    </p>
    <p>
    Open to the world and people, we support new ideas and strengthen team spirit!
    </p>
    `,
  },
  sections: [
    {
      title: {
        pl: 'Misja',
        en: 'Mission',
      },
      desc: {
        pl: 'Wspólnie z naszymi Klientami budujemy przewagę konkurencyjną na rynku, dostarczając optymalne rozwiązania logistyczne. Razem jesteśmy silni i skuteczni.',
        en: 'Together with our customers, we build a competitive advantage on the market by providing optimal logistics solutions. Together we are strong and effective.',
      },
    },
    {
      title: {
        pl: 'Wizja',
        en: 'Vision',
      },
      desc: {
        pl: 'Chcemy być najlepszą firmą na polskim rynku usług spedycyjnych, cenioną przez Klientów i lubianą przez Pracowników.',
        en: 'We want to be the best company on the Polish market of forwarding services, valued by customers and liked by employees.',
      },
    },
  ],
}
