import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Banner from '../../components/Banner'
import { useLangContext } from '../../context/lang.context'
import { alpha, colors, s, sDesc, sTextGradientLight } from '../../style'

const content = [
  {
    year: 2012,
    title: {
      pl: 'POCZĄTEK DZIAŁALNOŚCI',
      en: 'COMMENCEMENT OF BUSINESS',
    },
    desc: {
      pl: 'W 2012 w ramach struktury Omida S.A. powstał dział SEA AND AIR skoncentrowany na obsłudze ładunków drogą morską',
      en: 'In 2012, within the structure of Omida S.A., the SEA AND AIR department was established, focused on handling cargo by sea',
    },
  },
  {
    year: 2013,
    title: {
      pl: 'POWSTANIE DZIAŁU AGENCJI CELNEJ',
      en: 'FOUNDING OF CUSTOMS AGENCY',
    },
    desc: {
      pl: 'Otwarcie oddziału w Krakowie i oddziału w Warszawie',
      en: 'Opening of a branch in Cracow and a branch in Warsaw',
    },
  },
  {
    year: 2014,
    title: {
      pl: 'UZYSKANIE CERTYFIKATU AEO',
      en: 'OBTAINING AEO CERTIFICATE',
    },
    desc: {
      pl: 'Wyodrębnienie spółki Omida Sea And Air Sp. z o.o. Zmiana siedziby firmy na biuro w Gdyni',
      en: "Separation of Omida Sea And Air Sp. z o.o. Change of the company's headquarters to the office in Gdynia",
    },
  },
  {
    year: 2015,
    title: {
      pl: 'NOWE PERSPEKTYWY',
      en: 'NEW PROSPECTS ',
    },
    desc: {
      pl: 'Uzyskanie Członkostwa IATA',
      en: 'Obtaining IATA Membership',
    },
  },
  {
    year: 2016,
    title: {
      pl: 'OTWARCIE ODDZIAŁU W SŁUBICACH',
      en: 'OPENING OF BRANCH IN SŁUBICE',
    },
    desc: {
      pl: '',
      en: '',
    },
  },
  {
    year: 2017,
    title: {
      pl: 'NOWE DZIAŁY',
      en: 'NEW BRANCHES',
    },
    desc: {
      pl: 'Uruchomienie działów Project Cargo oraz Break Bulk',
      en: 'Launching the Project Cargo and Break Bulk departments',
    },
  },
  {
    year: 2018,
    title: {
      pl: 'OTWARCIE ODDZIAŁU W GLIWICACH',
      en: 'OPENING OF BRANCH IN GLIWICE',
    },
    desc: {
      pl: '',
      en: '',
    },
  },
  {
    year: 2019,
    title: {
      pl: 'NOWA FORMA PRAWNA',
      en: 'NEW LEGAL FORM',
    },
    desc: {
      pl: 'Przekształcenie formy prawnej na spółkę akcyjną Omida Sea And Air S.A.',
      en: 'Transformation of legal form into a public limited company - Omida Sea And Air S.A.',
    },
  },
  {
    year: 2020,
    title: {
      pl: 'CENTRUM LOGISTYCZNE',
      en: 'LOGISTICS CENTRE',
    },
    desc: {
      pl: 'Otwarcie Magazynu do obsługi sklepów e‑commerce w Warszawie',
      en: 'Opening of the Warehouse for e‑commerce stores in Warsaw',
    },
  },
  {
    year: 2021,
    title: {
      pl: 'TIME CRITICAL',
      en: 'TIME CRITICAL',
    },
    desc: {
      pl: 'Otwarcie oddziału Time Critical we Wrocławiu',
      en: 'Opening of the Time Critical branch in Wrocław',
    },
  },
  {
    year: 2023,
    title: {
      pl: 'OTRZYMANIE CERTYFIAKTU GREAT PLACE TO WORK®',
      en: 'OBTAINING GREAT PLACE TO WORK® CERTIFICATE',
    },
    desc: {
      pl: '',
      en: '',
    },
  },
  {
    year: 2023,
    title: {
      pl: '10-LECIE OMIDA SEA AND AIR S.A.',
      en: '10TH ANNIVERSARY OF OMIDA SEA AND AIR S.A.',
    },
    desc: {
      pl: '',
      en: '',
    },
  },
  {
    year: 2024,
    title: {
      pl: 'PONOWNE UZYSKANIE CERTYFIKATU GREAT PLACE TO WORK®',
      en: 'OBTAINING GREAT PLACE TO WORK® CERTIFICATE AGAIN',
    },
    desc: {
      pl: '',
      en: '',
    },
  },
]

const label = {
  text: {
    pl: 'Historia',
    en: 'History',
  },
  color: colors.brandBack,
}

const BannerHistory = () => {
  const { lang } = useLangContext()
  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/banners/company.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <Banner image={image} label={label} noSection effectReverse>
      <div css={sContainer}>
        <div css={sLine} />
        {content.map(({ year, title, desc }, id) => (
          <div
            key={id}
            css={[
              sHistory,
              id % 2 === 0
                ? { [s.md]: { textAlign: 'right', alignItems: 'flex-end' } }
                : { textAlign: 'left' },
            ]}
          >
            <span css={id % 2 === 1 && { [s.md]: { marginTop: '8rem' } }}>
              {year}
            </span>
            <h3>{title[lang]}</h3>
            <p>{desc[lang]}</p>
          </div>
        ))}
      </div>
    </Banner>
  )
}

const sContainer = {
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 10,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '16rem',
  rowGap: '4rem',
  height: 'max-content',
  flexShrink: 0,
  flexGrow: 0,
  [s.sm_down]: {
    gridTemplateColumns: '1fr',
    columnGap: '2rem',
    rowGap: '3rem',
  },
}

const sHistory = {
  display: 'flex',
  flexDirection: 'column',
  mixBlendMode: 'overlay',

  span: {
    fontSize: '6rem',
    fontWeight: 700,
    letterSpacing: '-0.05em',
    width: 'max-content',
    ...sTextGradientLight,
    [s.sm_down]: {
      fontSize: '4rem',
    },
  },

  h3: {
    textTransform: 'uppercase',
    marginBottom: '1rem',
    color: colors.brandLighter,
    fontWeight: 700,
    fontSize: '1.25rem',
    letterSpacing: '0em',
    [s.sm_down]: {
      fontSize: '1rem',
    },
  },

  p: {
    ...sDesc,
    fontSize: '1rem',
    color: colors.brandLighter.concat(alpha[60]),
    [s.sm_down]: {
      fontSize: '0.875rem',
    },
  },
}

const sLine = {
  [s.sm_down]: {
    display: 'none',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '100%',
  width: 10,
  zIndex: 11,
  background: `linear-gradient(to bottom, ${colors.brand.concat(
    alpha[0]
  )}, ${colors.brandLight.concat(alpha[16])}, ${colors.brandLight.concat(
    alpha[60]
  )})`,
  // mixBlendMode: 'overlay',
  borderRadius: 5,
}

export default BannerHistory
